<template>
    <transition v-if="!animation" name="fade">
        <div id="property">
            <div  v-if="!loading" class="property-container">
                <div id="slides">
                <div class="main-panel-container">
                    <vueper-slides :bullets="false" @slide="activeThumbnail" ref="vueperslides1" id="mainSlides"
                        :slide-ratio="1 / 4" :dragging-distance="70" :fixed-height="true" class="main-slides-container"
                        :class="{ 'slides-with-map': $store.getters.showMap, 'accesibility-mode': $store.getters.accessibilitySetting.showAccessibilityBar}"
                        prevent-y-scroll>
                        <vueper-slide v-for="(slide, i) in property.Slideshow.data" :key="i"
                            :image="url+slide.attributes.url" class="main_slides">
                            <template v-slot:loader>
                                <Spinner size="large" message="Loading..." line-fg-color="#000"
                                    style="padding: 5rem 0;" />
                            </template>
                        </vueper-slide>
                    </vueper-slides>
                    <GmapMap v-if="$store.getters.showMap" :center="marker" :zoom="15" map-type-id="terrain"
                        :style="'width: 40%;'" :options="mapStyle" class="map">
                        <GmapMarker v-for="(p, i) in propertiesWithMap" :key="i + '_marker'"
                            :position="{lat: parseFloat(p.lat), lng: parseFloat(p.lng)}" :clickable="true"
                            :icon="p.lat === marker.lat && p.lng === marker.lng ? selectedMarkerIcon : markerIcon"
                            @click="goToProperty(p, i)">
                        </GmapMarker>
                    </GmapMap>
                </div>
                <vueper-slides v-if="property.Slideshow.data" id="thumbnails" ref="vueperslides2" :slide-ratio="1 / 8"
                    :dragging-distance="50" @slide="activeSlide" :visible-slides="Math.ceil(window.width/100)"
                    :bullets="false" :arrows="false" :fixed-height="window.width > 576 ? '80px' : '50px'">
                    <vueper-slide v-for="(slide, i) in property.Slideshow.data" :key="i"
                        @click.native="$refs.vueperslides2.goToSlide(i)" :aria-label="`Go to slide ${i}`">
                        <template v-slot:content>
                            <div class="vueperslide__content-wrapper">
                                <img :src="url+slide.attributes.formats.thumbnail.url" alt="property thumnail" />
                            </div>
                        </template>
                    </vueper-slide>
                </vueper-slides>
                <modal name="example" class="modal-container" :adaptive="true"
                    :width="window.width > 576 ? '80%' : '95%'" :height="window.width > 576 ? '100%' : '95vw'">
                    <div class="floorplan-content">
                        <button @click="turnOffModal">
                            <img src="/images/lightbox-close.svg" class="close-icon" alt="close icon" />
                        </button>
                        <vueper-slides class="modal-slideshow no-shadow" :bullets="true" bullets-outside arrows-outside
                            :slide-ratio="1 / 4" :dragging-distance="70"
                            :fixed-height="window.width > 576 ? '80vh' : '95vw'" prevent-y-scroll>
                            <vueper-slide v-for="(slide, i) in property.Floorplans.data" :key="i"
                                :image="url+slide.attributes.url" class="floor-plan-slide">
                            </vueper-slide>
                        </vueper-slides>
                    </div>
                </modal>
            </div>
            <div class="title">
                <h1>
                    {{property.Address}}
                </h1>
                <p>
                    {{property.City}}, {{property.State}} {{property.Zip}}
                </p>
            </div>
            <div class="property-info">
                <div class="description">
                    <span :class="property.Status">{{property.Status | startCase}}</span>
                    <p> {{property.Price | priceFormat}} </p>
                    <p>{{$t('message.homeproperty.beds')}}: {{property.Bedrooms}} </p>
                    <p>{{$t('message.homeproperty.baths')}}: {{property.Bathrooms}} </p>
                </div>
            </div>
            <div class="mini-map" v-if="property.lng && property.lat">
                <Map :height="400" :marker="{'lat': Number(property.lat), 'lng':Number(property.lng)}" />
            </div>
            <div class="description_features" v-bind:class="{ border_bottom: !property.VideoID}">
                <read-more class="contents_rendered" more-str="MORE" :text="markdownToHTML(property.Content)"
                    less-str="LESS" :max-chars="1000"></read-more>
                <div class="features">
                    <div class="buttons">
                        <a v-if="property.VirtualTourLink" :href="property.VirtualTourLink" target="_blank"
                            rel="noopener noreferrer" class="btn btn_virtural_tour" aria-label="Go to Virtual Tour">
                            <img src="/images/walking-solid.svg" alt="Virtual Tour link" class="virtual_tour_image">
                            <span>Virtual Tour</span>
                        </a>
                        <a v-if="property.Floorplans.data" href="#" class="btn" @click.prevent="toggleModal"
                            aria-label="Open Floor Plan Popup">
                            <img src="/images/icon-floor-plan.svg" alt="Floor Plan" class="floorplan_image">
                            <span>Floor Plans</span>
                        </a>
                    </div>
                    <h3>Features</h3>
                    <p class="feature" v-for="(feature,i) in property.Features" :key="i">
                        {{feature.Feature}}
                    </p>
                </div>
            </div>
            <div id="video" v-if="property.VideoID" class='embed-container'>
                <iframe title="video" :src="'https://player.vimeo.com/video/' + property.VideoID" frameborder="0"
                    allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <div class="agents" v-if="agents">
                <div class="agent" v-for="(agent,i) in agents" :key="i">
                    <router-link :to="`/agent/${agent.Slug}`"
                        :aria-label="`Go to agent ${agent.FirstName} ${agent.LastName} profile `">
                        <img :src="url+agent.Image.data.attributes.url" :alt="`${agent.FirstName} ${agent.LastName}`">
                    </router-link>
                    <p class="first">{{agent.FirstName}} {{agent.LastName}}</p>
                    <p class="no_bold">{{agent.Subtitle}}</p>
                    <p>{{agent.Phone}}</p>
                    <p>{{agent.Email}}</p>
                </div>
            </div>
        </div>
        <div v-if="loading" class="loader-container">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;" />
        </div>
    </div>
</transition>
</template>

<script>
    import Spinner from 'vue-simple-spinner';
    import {
        VueperSlides,
        VueperSlide
    } from 'vueperslides';
    import {
        mapActions,
        mapState
    } from 'vuex';
    import Map from "../components/Map";

    const md = require('markdown-it')({
        html: true
    })

    export default {
        name: "property",
        components: {
            Spinner,
            VueperSlides,
            VueperSlide,
            Map
        },
        data() {
            return {
                agents: [],
                testdata: undefined,
                title: "Cerda Zein | Property",
                currentIndex: 0,
                loading: true,
                animation: false,
                nodatafound: false,
                property: {},
                images: [],
                window: {
                    width: 0,
                    height: 0
                },
                url: 'https://strapi.cerda-zein.com',
                markerIcon: "/images/marker_black.png",
                selectedMarkerIcon: "/images/marker_orange.png",
                mapStyle: {
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                    styles: [{
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [{
                                    "color": "#e9e9e9"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [{
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.fill",
                            "stylers": [{
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 17
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [{
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 29
                                },
                                {
                                    "weight": 0.2
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry",
                            "stylers": [{
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 18
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry",
                            "stylers": [{
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "geometry",
                            "stylers": [{
                                    "color": "#f5f5f5"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [{
                                    "color": "#dedede"
                                },
                                {
                                    "lightness": 21
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.stroke",
                            "stylers": [{
                                    "visibility": "on"
                                },
                                {
                                    "color": "#ffffff"
                                },
                                {
                                    "lightness": 16
                                }
                            ]
                        },
                        {
                            "elementType": "labels.text.fill",
                            "stylers": [{
                                    "saturation": 36
                                },
                                {
                                    "color": "#333333"
                                },
                                {
                                    "lightness": 40
                                }
                            ]
                        },
                        {
                            "elementType": "labels.icon",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "geometry",
                            "stylers": [{
                                    "color": "#f2f2f2"
                                },
                                {
                                    "lightness": 19
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.fill",
                            "stylers": [{
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 20
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry.stroke",
                            "stylers": [{
                                    "color": "#fefefe"
                                },
                                {
                                    "lightness": 17
                                },
                                {
                                    "weight": 1.2
                                }
                            ]
                        },
                        {
                            featureType: "poi",
                            stylers: [{
                                visibility: "off"
                            }]
                        },
                        {
                            featureType: "transit",
                            stylers: [{
                                visibility: "off"
                            }]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "labels",
                            "stylers": [{
                                "visibility": "off"
                            }]
                        }
                    ]
                }
            }
        },
        computed: {
            slideshowHeight: function () {
                switch (true) {
                    case (this.window.width > 2000):
                        return '1400px';
                    case (this.window.width < 2000 && this.window.width > 1800):
                        return '1200px';
                    case (this.window.width < 1800 && this.window.width > 1600):
                        return '1100px';
                    case (this.window.width < 1600 && this.window.width > 1400):
                        return '1000px';
                    case (this.window.width < 1400 && this.window.width > 1200):
                        return '900px';
                    case (this.window.width < 1200 && this.window.width > 576):
                        return '700px';
                    case (this.window.width < 576):
                        return '300px';
                    default:
                        return '800px';
                }
            },
            slug() {
                return this.$route.params.slug;
            },
            marker() {
                return {
                    'lat': Number(this.property.lat),
                    'lng': Number(this.property.lng)
                }
            },
            ...mapState(['currentFilterOptions']),
            filteredProperties:function(){
            let filteredProperties =  this.$store.getters.properties;
            if (this.componentKey < -1) {
                return filteredProperties;
            }
            if(this.currentFilterOptions[0].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.category === this.currentFilterOptions[0].value;
                })
            }
            if(this.currentFilterOptions[1].value !== 'any'){
                if(this.currentFilterOptions[1].value === 'available' || this.currentFilterOptions[1].value === 'comingSoon'){
                    filteredProperties = filteredProperties.filter((property) => {
                        return property.Status === 'available' || property.Status === 'comingSoon';
                    })
                } else {
                    filteredProperties = filteredProperties.filter((property) => {
                        return property.Status === this.currentFilterOptions[1].value;
                    })
                }
            }

            if(this.currentFilterOptions[2].value[1]){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.Price <= this.currentFilterOptions[2].value[1] && property.Price >= this.currentFilterOptions[2].value[0]
                })
            }

            if(this.currentFilterOptions[3].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    if(this.currentFilterOptions[3].value[1] === 10){
                        return Number(property.Bedrooms) >= Number(this.currentFilterOptions[3].value[0]);
                    }
                    return Number(property.Bedrooms) >= Number(this.currentFilterOptions[3].value[0]) && Number(property.Bedrooms) <= Number(this.currentFilterOptions[3].value[1]);
                })
            }
            if(this.currentFilterOptions[4].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    if(this.currentFilterOptions[4].value[1] === 10){
                        return Number(property.Bathrooms) >= Number(this.currentFilterOptions[4].value[0]);
                    }
                    return Number(property.Bathrooms) >= Number(this.currentFilterOptions[4].value[0]) && Number(property.Bathrooms) <= Number(this.currentFilterOptions[4].value[1]);
                })
            }
            if(this.currentFilterOptions[5].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.BuildingType === this.currentFilterOptions[5].value;
                })
            }
            if(this.currentFilterOptions[6].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.Pets === this.currentFilterOptions[6].value;
                })
            }
            if(this.currentFilterOptions[7].value !== 'any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.Laundry === this.currentFilterOptions[7].value;
                })
            }
            if(this.currentFilterOptions[8] !== 'Any'){
                filteredProperties = filteredProperties.filter((property) => {
                    return property.City?.trim().toLowerCase() === this.currentFilterOptions[8].trim().toLowerCase();
                })
            }

            return filteredProperties;
        },
            propertiesWithMap() {
                return this.filteredProperties.filter((property) => {
                    return property.lat !== '' && property.lng !== '';
                })
            },
        },
        methods: {
            activeThumbnail(event) {
                this.$refs.vueperslides2.goToSlide(event.currentSlide.index, {
                    emit: false
                })
            },
            activeSlide(event) {
                this.$refs.vueperslides1.goToSlide(event.currentSlide.index, {
                    emit: false
                })
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
            },
            toggleModal() {
                this.$modal.show('example');
            },
            turnOffModal() {
                this.$modal.hide('example');
            },
            markdownToHTML(str) {
                if (!str) {
                    return '';
                }
                return md.render(str);
            },
            getData() {
                this.loading = true;
                this.animation = true;
                let category = '';
                if (this.$route.params.category !== undefined && this.$route.params.category.length > 0) {
                    category = this.$route.params.category;
                }
                this.axios.get(
                        `https://strapi.cerda-zein.com/api/${category}?populate[Floorplans]=*&populate[Slideshow]=*&populate[FeaturedImage]=*&populate[Features]=*&populate[agents][populate]=*&filters[Slug][$eq]=${this.slug}`
                    )
                    .then((response) => {
                        if (response.data.data.length > 0) {
                            this.property = response.data.data[0].attributes;
                            if (typeof this.property.Slideshow === 'undefined' || this.property.Slideshow ===
                                false) {
                                this.property.Slideshow = [{
                                    url: this.url + this.property.FeaturedImage.data.attributes.url
                                }]
                            }
                            this.title = this.property.Address;
                            if (this.property.agents.data.length > 0) {
                                this.agents = this.property.agents.data.map(a => a.attributes);
                            }
                            this.loading = false;
                        } else {
                            this.nodatafound = !this.nodatafound
                        }
                    })
                this.currentIndex = this.$store.getters.properties.findIndex(property => property.Slug === this.$route
                    .params.slug);
                this.setPropertyIndex(this.currentIndex);
                setTimeout(()=> {
                    this.animation = false
                }, 0);
            },
            goToProperty(property) {
                this.$router.push(`/property/${property.category}s/${property.Slug}`);
            },
            ...mapActions(['setPropertyIndex']),
        },
        watch: {
            slug() {
                this.getData();
            }
        },
        mounted: function () {
            this.getData();
        },
        metaInfo() {
            let data = this.title;
            return {
                title: data ? data : "Property",
                titleTemplate: '%s | Cerda-Zein',
                meta: [{
                    name: 'description',
                    content: "Cerda-Zein"
                }]
            }
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize);
            this.$store.dispatch('showMap');
        },
    }
</script>

<style lang="scss" scoped>
    .loader-container {
        min-height: 90vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .border_bottom {
        border-bottom: 1px solid #e1e1e1;
    }

    .title {
        border-top: 1px solid #e1e1e1;
        margin-top: 48px;
        padding-top: 32px;
        text-align: center;
        font-family: $secondaryFont;

        h1 {
            font-size: 2rem;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        p {
            font-size: 1.25rem;
        }
    }

    .property-info {
        margin: 48px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #f5f5f5;
        font-family: $secondaryFont;

        .description {
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: pre-line;
            padding: 40px 0;
            gap: 40px;

            span {
                display: inline-block;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 0.8rem;
                letter-spacing: 1.2px;
                padding: 2px 16px;

                &.available,
                &.comingSoon {
                    background: coral;
                    color: #fff;
                }

                &.sold {
                    color: #fff;
                    background: #000;
                }

                &.pending {
                    background: #fff;
                }

                &.rented {
                    color: #fff;
                    background: #000;
                }
            }

            p {
                text-transform: uppercase;
                font-size: 1.25rem;
                letter-spacing: 1.2px;
                font-weight: 500;
                border-left: 1px solid #000;
                padding: 20px 0;
                padding-left: 40px;
            }
        }
    }

    .description_features {
        display: flex;
        margin: 0 64px;
        padding: 32px 0;

        .contents_rendered {
            width: 60%;
            margin: 0;
            margin-right: 64px;
        }

        .features {
            display: flex;
            flex-direction: column;
            flex: 1;

            .buttons {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
            }

            h3 {
                text-transform: uppercase;
                font-size: 1rem;
                letter-spacing: 1.5px;
            }

            p {
                margin: 0;
                padding: 16px 0;
                border-bottom: 1px solid #e1e1e1;
            }

            p:last-child {
                border-bottom: none;
            }

            .btn {
                .virtual_tour_image {
                    width: 15px;
                    height: auto;
                    margin-right: 16px;
                }
                .floorplan_image {
                    width: 25px;
                    height: auto;
                    margin-right: 16px;
                }
            }

            .btn_virtural_tour{
                margin-bottom: 10px;
            }
        }
    }

    .embed-container {
        position: relative;
        padding-bottom: 56.25%;
        overflow: hidden;
    }

    .embed-container iframe,
    .embed-container object,
    .embed-container embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .agents {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: 64px 0;

        .agent {
            max-width: 25%;
            width: 25%;
            min-width: 200px;
            padding: 32px 16px;
            text-align: center;

            img {
                width: 100px;
                height: 100px;
                object-fit: cover;
                border-radius: 50px;
                border: 1px solid #e1e1e1;
            }

            p {
                text-transform: uppercase;
                font-weight: 700;
                margin: 8px 0;

                &.first {
                    margin-top: 18px;
                }

                &.no_bold {
                    font-weight: 400;
                }
            }
        }
    }

    .floorplan-content {
        button {
            background: transparent;
            border: none;
            position: absolute;
            right: 20px;
            top: 20px;

            &:hover {
                cursor: pointer;
            }
        }

        .close-icon {
            width: 40px;
            height: 40px;
        }
    }

    .modal-slideshow {
        margin: 64px;
    }

    .main-panel-container {
        display: flex;
        gap: 10px;

        .main-slides-container {
            width: 100%;
        }

        .slides-with-map {
            width: 60%;
        }
    }

    .mini-map {
        display: none;
    }

    .main-panel-container {
        .vueperslides--fixed-height {
            height: calc(100vh - 47px - 130px);

            &.accesibility-mode {
                height: calc(100vh - 44px - 47px - 130px);
            }
        }
    }


    @media (max-width: $tablet) {
        .main-panel-container {
            .vueperslides--fixed-height {
                height: calc(60vh - 47px - 130px);

                &.accesibility-mode {
                    height: calc(60vh - 44px - 47px - 130px);
                }
            }
        }
    }

    @media (max-width: $mobile) {
        .main-panel-container {
            .vueperslides--fixed-height {
                height: 300px;

                &.accesibility-mode {
                    height: 300px;
                }
            }
        }

        .main-panel-container {
            .slides-with-map {
                width: 100%;
            }
        }

        .map {
            display: none;
        }

        .mini-map {
            display: block;
        }

        .property-info {
            margin: 48px 0;

            .description {
                display: flex;
                flex-direction: column;
                padding: 40px 0;
                gap: 10px;

                span {
                    margin-bottom: 20px;
                }

                p {
                    border-left: unset;
                    padding: 0;
                    padding-left: 0;
                }
            }
        }

        .description_features {
            display: block;
            margin: 0;
            margin-bottom: 32px;
            padding-top: 0;

            .contents_rendered {
                width: unset;
                margin: 0 32px;
            }

            .features {
                flex: 1;
                margin: 32px 32px 0 32px;
            }
        }

        .modal-slideshow {
            margin: 0;
            margin-top: 64px;
            margin-bottom: 32px;
        }

        .modal-container {
            top: 80px;
        }

        .agents {
            margin: 32px 0;

            .agent {
                max-width: 80%;
                width: 80%;
                padding: 24px 16px;
                text-align: center;

                img {
                    width: 100;
                    height: 100;
                    border-radius: 50;
                }

                p {
                    margin: 0;
                    letter-spacing: 1.2px;

                    &.first {
                        margin-top: 16px;
                    }
                }
            }
        }
    }
</style>

<style lang="scss">
    #slides .vueperslides__arrow--prev {
        left: 1em;
    }

    #slides .vueperslides__arrow--next {
        right: 1em;
    }

    #thumbnails {
        padding: 16px 0;
        margin-bottom: 32px !important;
    }

    #thumbnails .vueperslide {
        padding: 3px 0;
    }

    #thumbnails .vueperslide img {
        width: 80px;
        height: 100%;
    }

    #thumbnails .vueperslide--active img {
        border: 3px solid #000;
    }

    .contents_rendered {
        opacity: 0.7;
    }

    .contents_rendered a {
        color: #000;
    }

    #readmore {
        color: #000;
        font-weight: 700;
        text-decoration: none;
        font-size: 0.85rem;
        line-height: 1.5rem;
        letter-spacing: 1.2px;
    }

    @media (max-width: 576px) {
        #thumbnails .vueperslide img {
            width: 50px;
        }
    }

    .modal-container {
        .vueperslide {
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
</style>